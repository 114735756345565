<template>
  <div class="auth-v2 mobile-not-scroll">
    <b-row class=" m-0">
      <b-col lg="12" class="pl-0 pr-0">
        <div class="account-cls">
          <AccountDetails />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import UserDetails from "@/layouts/components/ConsumerOnboarding/UserDetails.vue";
import AccountDetails from "@/layouts/components/ConsumerOnboarding/AccountDetails.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend,
  OverlayPlugin,
  BOverlay
} from "bootstrap-vue";
import { required } from "@validations";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    FormWizard,
    TabContent,
    UserDetails,
    AccountDetails
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
