<template>
  <div class="custom-width">
    <b-card-title title-tag="h2" class="font-weight-bold mb-0">
      {{ $t("user.InvitedLogin") }}
    </b-card-title>
    <b-card-text class="bt-40">
      {{ $t("user.AcceptInvite") }}
    </b-card-text>
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2">
        <b-form-group :label="$t('user.Fname')">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input type="text" :placeholder="$t('user.EnterFulln')" />
          </b-input-group>
        </b-form-group>
        <b-form-group :label="$t('user.Email')">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="MailIcon" />
            </b-input-group-prepend>
            <b-form-input type="text" :placeholder="$t('user.EnterEmail')" />
          </b-input-group>
        </b-form-group>
        <b-form-group :label="$t('user.Username')">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input type="text" :placeholder="$t('user.EnterUsername')" />
          </b-input-group>
        </b-form-group>
        <b-form-group :label="$t('user.Password')">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="LockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-password"
              type="password"
              :placeholder="$t('user.EnterPassword')"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-button type="submit" variant="primary" block class="mt-16">
          {{ $t("user.Login") }}
        </b-button>
      </b-form>
    </validation-observer>
    <b-card-text class="text-center mt-2">
      <span>{{ $t("user.BeenHere") }} </span>
      <b-link>
        <span>{{ $t("user.Login") }}</span>
      </b-link>
    </b-card-text>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend,
  BAvatar
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    ValidationProvider,
    ValidationObserver,

    BInputGroupPrepend,
    BAvatar
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      // validation rulesimport store from '@/store/index'
      required,
      email
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    }
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
